export const MenuItems = [
  {
    title: 'Inspiration',
    path: '/About/Inspiration',
    cName: 'dropdown-link'
  },
  {
    title: 'Our Mission',
    path: '/about/MissionRows',
    cName: 'dropdown-link'
  },
  {
    title: 'Partner In Success',
    path: '/about/PartnerInSuccess',
    cName: 'dropdown-link'
  },
  {
    title: 'Founders Message',
    path: '/vp',
    cName: 'dropdown-link'
  },

];

import React from 'react'
import Marquee from "react-fast-marquee";

function ReactMarqueeNotification() {
    return (
        <div>
            <Marquee className='p-4 w-2' pauseOnHover={true}>
                Admissions Open: 2024-25 : | B.A | B.Com | B.Sc| BCA | B.Tech | D.Pharm | B.Pharm | LLB | LLM | Diploma | MBA | MCA | MA  
            </Marquee>
        </div>
    )
}

export default ReactMarqueeNotification
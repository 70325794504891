import React from 'react'
import { Link } from 'react-router-dom'

function OurCourses({ title, courses }) {
  return (
    <div>
      <h1 className='text-3xl mt-16 text-center text-white rounded-lg bg-gradient-to-r from-[#025091] to-[#71C5EE]'>{title}</h1>
      {courses?.map((course) => {
        return (
          <Link
            key={course.link}
            to={course.link}
            className="grid grid-rows-3 bg-gradient-to-r from-[#025091] to-[#FFFFFF] text-center text-base  rounded-full  w-40 h-40 mx-auto" >
            <div className='text-center pt-14'>
              {course.CourseName}
            </div>

          </Link>
        )
      })}
      
    </div>
  )
}

export default OurCourses







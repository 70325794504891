import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
function CarouselHome({ src, mainHeading, subHead, height }) {
    console.log(src);
    return (
        <div>
            <Carousel showIndicators={false}  infiniteLoop autoPlay interval={5000} showThumbs={false}  >
                <div>
                    {/* <img src={src} /> */}
                    <img src="https://aiu.edu.in/~lib/image/slider/SLIDE-9.jpg" />

                </div>
                <div>
                    <img src="https://aiu.edu.in/~lib/image/slider/S5.jpg" />

                </div>
                <div>
                    <img src="https://aiu.edu.in/~lib/image/slider/SLIDE-6.jpg" />
                </div>
                <div>
                    <img src="https://aiu.edu.in/~lib/image/slider/SLIDE-7.jpg" />
                </div>
                <div>
                    <img src="https://aiu.edu.in/~lib/image/slider/SLIDE-11.jpg" />
                </div>
                <div>
                    <img src="https://aiu.edu.in/~lib/image/slider/SLIDE-8.jpg" />
                </div>

            </Carousel>
        </div>
    )
}
export default CarouselHome